import React, { useEffect } from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { getValue, isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getCustomer, updateCustomer } from "../../api/admin/AdminCustomerApi";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import AdminCustomerForm from "../../components/admin/AdminCustomerForm";
import PageLoading from "../../components/ui-core/PageLoading";
import { MERCHANTS } from "../../constants/MerchantTypes";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import AdminCustomerContractForm from "../../components/admin/AdminCustomerContractForm";
import {
  getCompanyEndPointSettings,
  updateCompanyEndPointSettings,
  updateCompanyFinanceSettings,
} from "../../api/admin/AdminCompanyApi";
import { formatDateToUrl, safeParseDate } from "../../helpers/FormatUtils";
import AdminCustomerCompanyEndPointForm from "../../components/admin/AdminCustomerCompanyEndPointForm";
import { makeStyles } from "@material-ui/core";
import AdminCustomerSettingForm from "../../components/admin/AdminCustomerSettingForm";
import AdminCustomerItemCommunicationContainer from "./AdminCustomerItemCommunicationContainer";

const styles = makeStyles({
  root: {
    height: "auto !important",
    padding: 10,
  },
});

const enhancer = compose(
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const customerStream = propsStream
      .map(fp.flow(fp.get("params.customerId"), fp.toInteger))
      .distinctUntilChanged()
      .switchMap(customerId =>
        getCustomer(customerId).repeatWhen(() => onRequestRefreshStream),
      )
      .let(mapObjectResponseStream)
      .map(response => response.update("payload", toCamelCase))
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(customerStream, (props, customer) => ({
      ...props,
      onRequestRefresh,
      isLoading: customer.get("pending"),
      customer: customer.get("payload"),
    }));
  }),
);

AdminCustomerItemDetailsContainer.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  isLoading: PropTypes.bool,
  customer: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerItemDetailsContainer(props) {
  if (props.isLoading) {
    return <PageLoading isLoading={true} />;
  }
  const { getLocalisationMessage } = props;

  const [companyEndPoint, setCompanyEndPoint] = React.useState({});
  const classes = styles();

  useEffect(() => {
    if (getValue(props.customer, "company.id")) {
      getCompanyEndPointSettings(getValue(props.customer, "company.id")).then(
        res => {
          setCompanyEndPoint(res.data);
        },
      );
    }
  }, [props.customer]);

  const [show, setShow] = React.useState(
    getValue(props.customer, "merchantType") === MERCHANTS,
  );

  return (
    <AdminAppLayout
      className={classes.root}
      title={getLocalisationMessage("customers", "Customers")}
    >
      <FlexBox direction="column" style={{ gap: 16 }}>
        <AdminCustomerForm
          setShow={setShow}
          edit={true}
          initialValues={{
            ...props.customer,
            merchant: props.customer.merchantType === MERCHANTS,
            companyName: getValue(props.customer, "company.name"),
          }}
          onSubmit={fp.flow(toSnakeCase, values =>
            updateCustomer(props.params.customerId, values).catch(
              ResponseError.throw,
            ),
          )}
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully Saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedReference={getCachedReference}
          getReferencePredictions={getReferencePredictions}
        />

        {getValue(props.customer, "company.id", null) && show && (
          <AdminCustomerContractForm
            initialValues={{
              unlimited: getValue(props.customer, "unlimited", true),
              company: getValue(props.customer, "company"),
              contractAttachment: getValue(
                props.customer,
                "company.contractAttachment",
              ),
              contractStartDate: safeParseDate(
                getValue(props.customer, "company.contractStartDate"),
              ),
              contractEndDate: safeParseDate(
                getValue(props.customer, "company.contractEndDate"),
              ),
              innAttachment: getValue(props.customer, "company.innAttachment"),
              paymentTypes: getValue(props.customer, "company.paymentMethods"),
              creditLimit: getValue(props.customer, "creditLimit"),
            }}
            onSubmit={fp.flow(toSnakeCase, values => {
              updateCompanyFinanceSettings({
                ...values,
                contract_start_date: formatDateToUrl(
                  values.contract_start_date,
                ),
                contract_end_date: formatDateToUrl(values.contract_end_date),
              }).catch(ResponseError.throw);
            })}
            onSubmitSuccess={() => {
              props.onRequestRefresh();
              props.showSuccessMessage(
                getLocalisationMessage(
                  "successfully_saved",
                  "Successfully Saved",
                ),
              );
            }}
            onSubmitFail={props.showErrorMessage}
          />
        )}

        {getValue(props.customer, "company.id", null) && show && (
          <FlexBox direction="column">
            <AdminCustomerCompanyEndPointForm
              initialValues={{
                ...companyEndPoint,
                key1: Object.keys(getValue(companyEndPoint, "headers", {}))[0],
                key2: Object.values(
                  getValue(companyEndPoint, "headers", {}),
                )[0],
              }}
              onSubmit={values => {
                const data = {
                  company_id: getValue(props.customer, "company.id"),
                  endpoint: values.endpoint,
                  headers: {
                    [values.key1]: values.key2,
                  },
                };
                return updateCompanyEndPointSettings(data).catch(
                  ResponseError.throw,
                );
              }}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(
                  getLocalisationMessage("company_end_point_updated"),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {getValue(props.customer, "company.id", null) && show && (
          <FlexBox direction="column">
            <AdminCustomerSettingForm
              initialValues={{
                verificationRequired: getValue(
                  props.customer,
                  "verificationRequired",
                ),
                canCreateCodOrder: getValue(
                  props.customer,
                  "canCreateCodOrder",
                ),
              }}
              onSubmit={fp.flow(toSnakeCase, values =>
                updateCustomer(props.params.customerId, {
                  ...toSnakeCase(props.customer),
                  verification_required: values.verification_required,
                  can_create_cod_order: values.can_create_cod_order,
                }).catch(ResponseError.throw),
              )}
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_saved",
                    "Successfully Saved",
                  ),
                );
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        )}

        {getValue(props.customer, "company.id", null) && show && (
          <AdminCustomerItemCommunicationContainer
            params={props.params}
            location={props.location}
          />
        )}
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(AdminCustomerItemDetailsContainer);
