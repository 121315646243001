import React, { useEffect, useState } from "react";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Card,
  CardHeader,
  Button,
  CardContent,
  CardActions,
  IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import FormDateField from "../form/FormDateField";
import {
  ArrowRightAlt,
  CloudDownload,
  Done,
  Visibility,
} from "@material-ui/icons";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import { parseFloat } from "../../helpers/FormatUtils";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import FileUploadButton from "../deprecated/FileUploadButton";
import { getValue } from "../../helpers/DataUtils";
import { getAttachmentUrl } from "../../api/shared/FileApi";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "AdminCustomerContractForm",
    enableReinitialize: true,
  }),
  formValues("unlimited", "contractAttachment", "innAttachment"),
);

AdminCustomerContractForm.propTypes = {
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  unlimited: PropTypes.bool,
  contractAttachment: PropTypes.object,
  innAttachment: PropTypes.object,
};

function AdminCustomerContractForm(props) {
  const [contractUrl, setContractUrl] = useState("");
  const [innUrl, setInnUrl] = useState("");

  useEffect(() => {
    if (props.innAttachment && props.innAttachment.id) {
      getAttachmentUrl(props.innAttachment.id).then(r => {
        setInnUrl(r.data);
      });
    }
  }, [props.innAttachment]);

  useEffect(() => {
    if (props.contractAttachment && props.contractAttachment.id) {
      getAttachmentUrl(props.contractAttachment.id).then(r => {
        setContractUrl(r.data);
      });
    }
  }, [props.contractAttachment]);

  return (
    <Card style={{ width: "100%" }}>
      <PageLoading isLoading={props.submitting} />
      <form autoComplete="off" onSubmit={props.handleSubmit}>
        <CardHeader
          subheader={props.getLocalisationMessage("contract", "Contract")}
        />

        <CardContent>
          <FlexBox style={{ gap: 16 }} direction="column" flex={true}>
            <FlexBox style={{ gap: 16 }} flex={true}>
              <FlexBox flex={true}>
                <FileUploadButton
                  style={{ height: 45 }}
                  fullWidth={true}
                  variant="outlined"
                  onChange={fp.flow(fp.get("data"), value =>
                    props.change("contractAttachment", value),
                  )}
                  label={props.getLocalisationMessage(
                    "upload_contract",
                    "Upload Contract",
                  )}
                />
              </FlexBox>

              {props.contractAttachment && (
                <FlexBox justify="center" align="center">
                  <ArrowRightAlt fontSize="large" />
                </FlexBox>
              )}

              {props.contractAttachment && (
                <FlexBox flex={true} justify="center">
                  <FlexBox flex={true} align="center">
                    <Button
                      fullWidth={true}
                      variant="outlined"
                      disabled={true}
                      style={{ height: 45 }}
                    >
                      {getValue(props.contractAttachment, "name")}
                    </Button>
                  </FlexBox>

                  <FlexBox justify="flex-end">
                    <IconButton
                      onClick={() => {
                        fetch(contractUrl)
                          .then(response => response.blob())
                          .then(myBlob => {
                            let objectURL = URL.createObjectURL(myBlob);
                            if (myBlob && myBlob.size > 0) {
                              const iframe = document.createElement("iframe");
                              iframe.style.display = "none";
                              document.body.appendChild(iframe);
                              iframe.src = objectURL;
                              objectURL = URL.revokeObjectURL(myBlob);
                              window.setTimeout(() => {
                                iframe.contentWindow.print();
                              }, 1000);
                            }
                          });
                      }}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton href={contractUrl}>
                      <CloudDownload />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              <FlexBox flex={true}>
                <FormDateField
                  fullWidth={true}
                  name="contractStartDate"
                  hintText={props.getLocalisationMessage(
                    "contract_create_date",
                    "Contract Create Date",
                  )}
                />
              </FlexBox>

              <FlexBox flex={true}>
                <FormDateField
                  fullWidth={true}
                  name="contractEndDate"
                  hintText={props.getLocalisationMessage(
                    "contract_end_date",
                    "Contract End Date",
                  )}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox style={{ gap: 16 }} flex={true}>
              <FlexBox flex={true}>
                <FileUploadButton
                  style={{ height: 45 }}
                  variant="outlined"
                  fullWidth={true}
                  onChange={fp.flow(fp.get("data"), value =>
                    props.change("innAttachment", value),
                  )}
                  label={props.getLocalisationMessage(
                    "upload_inn",
                    "Upload INN",
                  )}
                />
              </FlexBox>

              {props.innAttachment && (
                <FlexBox
                  justify="center"
                  align="flex-start"
                  style={{ marginTop: 5 }}
                >
                  <ArrowRightAlt fontSize="large" />
                </FlexBox>
              )}

              {props.innAttachment && (
                <FlexBox align="flex-start" flex={true}>
                  <FlexBox flex={true} style={{ height: 45 }}>
                    <Button
                      fullWidth={true}
                      variant="outlined"
                      disabled={true}
                      style={{ height: 45 }}
                    >
                      {getValue(props.innAttachment, "name")}
                    </Button>
                  </FlexBox>

                  <FlexBox style={{ height: 45 }} justify="flex-end">
                    <IconButton
                      onClick={() => {
                        fetch(innUrl)
                          .then(response => response.blob())
                          .then(myBlob => {
                            let objectURL = URL.createObjectURL(myBlob);
                            if (myBlob && myBlob.size > 0) {
                              const iframe = document.createElement("iframe");
                              iframe.style.display = "none";
                              document.body.appendChild(iframe);
                              iframe.src = objectURL;
                              objectURL = URL.revokeObjectURL(myBlob);
                              window.setTimeout(() => {
                                iframe.contentWindow.print();
                              }, 1000);
                            }
                          });
                      }}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton href={innUrl}>
                      <CloudDownload />
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}

              <FlexBox direction="column" flex={true}>
                <FormChipAutoComplete
                  name="paymentTypes"
                  options={OrderPaymentTypes}
                  fullWidth={true}
                  formatOption={x =>
                    formatLocalisedPaymentType(x, props.getLocalisationMessage)
                  }
                  hintText={props.getLocalisationMessage("type_here_to_search")}
                  label={props.getLocalisationMessage("payment_method")}
                />

                <FormCheckbox
                  name="unlimited"
                  label={props.getLocalisationMessage("unlimited", "Unlimited")}
                />
              </FlexBox>

              <FlexBox flex={true}>
                {!props.unlimited && (
                  <FormTextField
                    name="creditLimit"
                    fullWidth={true}
                    parseOnBlur={parseFloat}
                    readOnly={props.unlimited}
                    label={props.getLocalisationMessage(
                      "credit_limit",
                      "Credit Limit",
                    )}
                  />
                )}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </CardContent>

        <CardActions>
          <FlexBox
            style={{ marginBottom: "1rem" }}
            flex={true}
            justify="flex-end"
          >
            <CustomButton
              style={{ marginLeft: ".5rem" }}
              variant={CONTAINED}
              color={SECONDARY}
              endIcon={<Done />}
              type="submit"
            >
              {props.getLocalisationMessage("save")}
            </CustomButton>
          </FlexBox>
        </CardActions>
      </form>
    </Card>
  );
}

export default enhancer(AdminCustomerContractForm);
