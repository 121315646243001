import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card, CardContent, CardHeader, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { Done } from "@material-ui/icons";
import FormCheckbox from "../form/FormCheckbox";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "AdminCustomerSettingForm",
    enableReinitialize: true,
  }),
);

AdminCustomerSettingForm.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function AdminCustomerSettingForm(props) {
  const { getLocalisationMessage } = props;

  return (
    <Card>
      <PageLoading isLoading={props.submitting} />
      <CardHeader
        subheader={getLocalisationMessage(
          "company_settings",
          "Company Settings",
        )}
      />

      <CardContent>
        <FlexBox direction="column" gutter={16}>
          <FlexBox flex={true}>
            <FormCheckbox
              name="verificationRequired"
              label={getLocalisationMessage(
                "face_id_verification",
                "Delivery verification with FACE ID",
              )}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <FormCheckbox
              name="canCreateCodOrder"
              label={getLocalisationMessage(
                "can_create_order_with_cod",
                "Can create order with COD",
              )}
            />
          </FlexBox>
        </FlexBox>
      </CardContent>

      <FlexBox justify="flex-end">
        <CardActions>
          <form onSubmit={props.handleSubmit}>
            <CustomButton
              style={{ marginLeft: ".5rem" }}
              variant={CONTAINED}
              color={SECONDARY}
              endIcon={<Done />}
              type="submit"
            >
              {getLocalisationMessage("submit", "Submit")}
            </CustomButton>
          </form>
        </CardActions>
      </FlexBox>
    </Card>
  );
}

export default enhancer(AdminCustomerSettingForm);
